import { mainAxios } from "libs/axios";

interface getAllLoan {
  page?: string;
  size?: string;
  querySearch?: string;
  contractStartDate?: string;
  orderByKey?: string;
  orderByValue?: string;
}

export const getAllLoanApi = (params: getAllLoan) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/loan-manager/get-all-loan`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

interface getCollateralInformation {
  loanManagerId?: string;
}

export const getCollateralInformationAPI = (
  params: getCollateralInformation
) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/mortgage_management/mortgage-management-info`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

export const getBorrowerNameApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/loan-manager/get-borrower`,
    notError: true,
    requiresToken: true,
  });
};

export const getGenerateContractApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/loan-manager/generate-contract`,
    notError: true,
    requiresToken: true,
  });
};

export type workImage = {
  name: string;
  type: string;
  image_url: string;
};

interface CreateLoan {
  // contractNumber: string;
  borrowerId?: number;
  borrowerName?: string;
  loanAmount?: number;
  profitRate?: number;
  midWayRefundFee?: number;
  rateDelay?: number;
  contractStartDate?: string;
  timeContract?: number;
  contractEndDate?: string;
  refundMethod?: string;
  typeContract?: string;
  imageLoanContract?: workImage[];
  imageMortgageContract?: workImage[];
  imageCashContract?: workImage[];
  imageUseCustomerInfo?: workImage[];
  status?: string;
  memo?: string;
}

export const postNewLoanAPI = (payload: CreateLoan) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/loan-manager/create-loan`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

//get data table detail loan

interface dataDetailLoanProps {
  loanId: number;
}

export const getDataDetailLoanApi = (params: dataDetailLoanProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/loan-manager/get-detail`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

//get data table debt repayment history detail loan

interface dataTableDebtRepaymentProps {
  loanId: number;
  page: number;
  size: number;
}

export const getDataDebtRepaymentApi = (
  params: dataTableDebtRepaymentProps
) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/repayment/get-repayment`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};



interface createDebtRepaymentHistoryProps {
  // loanManagerId: number;
  // repaymentHistoryDate: string;
  // type: string;
  // interestIncurred: number;
  // refundAmount: number;
  // unpaidAmount: number;
  // note: string;
  repaymentHistoryDate: any;
  repaymentType: string;
  loanManagerId: number;
  repaymentAmount: number;
  note: string;
}

export const createDebtRepaymentHistoryApi = (
  payload: createDebtRepaymentHistoryProps
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/repayment/create-repayment-history`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

interface dataEditDebtRepaymentProps {
  repaymentHistoryId: number;
  repaymentAmount: number;
  note: string;
}

export const editDebtRepaymentHistoryApi = (payload: dataEditDebtRepaymentProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/repayment/update-repayment-history`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};
//get data table other costs incurred detail loan

interface dataTableOtherCosts {
  loanId: number;
  page: number;
  size: number;
}

export const getDataOthersCostApi = (params: dataTableOtherCosts) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/cost/get-cost-other`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

interface createDataOthersCostProps {
  loanManagerId: number;
  costOtherDate: string;
  theAmountIncurred: number;
  refundAmount: number;
  note: string;
}

export const createDataOthersCostApi = (payload: createDataOthersCostProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/cost/create-cost-other`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

//calculator repayment
interface calculatorRepaymentProps {
  repaymentHistoryDate: any;
  repaymentType: string;
  loanManagerId: number;
  repaymentAmount: number;
}

export const calculatorRepaymentApi = (payload: calculatorRepaymentProps) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/repayment/calculate-repayment`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

//get Data HistoryChangeStatus\
interface getDataHistoryChangeStatusProps {
  loanId: number;
  page: number;
  size: number;
}

export const getDataHistoryChangeStatusApi = (
  params: getDataHistoryChangeStatusProps
) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/history/get-all`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

//delete DHistoryChangeStatus\
interface deleteHistoryChangeStatusProps {
  paramId: number;
}

export const deleteHistoryChangeStatusApi = (
  payload: deleteHistoryChangeStatusProps
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/history/delete`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

interface EditLoan {
  contractEndDate?: string;
  loanId?: number;
  timeContract?: number;
  refundMethod?: string;
  typeContract?: string;
  status?: string;
  classificationEnds?: string;
  imageLoanContract?: workImage[];
  imageMortgageContract?: workImage[];
  imageCashContract?: workImage[];
  imageUseCustomerInfo?: workImage[];
  contractNumber?: string;
  memo?: string;
  repurchaseTotalAmount?: any;
}

export const postLoanDetailsAPI = (payload: EditLoan) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/loan-manager/edit-loan-manager`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

interface totalCalculatedRepaymentPayload {
  loanAmount?: number;
  profitRate?: number;
  timeContract?: number;
}

export const totalCalculatedRepaymentAPI = (payload: totalCalculatedRepaymentPayload) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/loan-manager/calculate-for-repurchase-loan`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};