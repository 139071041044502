import { mainAxios } from "libs/axios";

/**Borrower Props type */
interface borrowerProps {
  page?: number;
  size?: number;
  querySearch?: string;
  type?: string;
  orderByKey?: string,
  orderByValue?: string,
}

export const borrowerApi = (params: borrowerProps) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/borrower/get-all-borrower`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};

/** create personal Borrower Props type */

export type workImage = {
  name: string;
  type: string;
  image_url: string;
};

interface createPersonalBorrowerProps {
  type: string;
  name: string;
  identityCardNumber: string;
  phoneNumber: string;
  address: string;
  address2: string;
  email: string;
  level: string;
  imageIdentityCard: workImage[];
  imagePersonalProfile: workImage[];
  imageAccountBook: workImage[];
  zipCode: string;
}

export const createPersonalBorrowerApi = (
  payload: createPersonalBorrowerProps
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/borrower/create-borrower`,
    payload: payload,
    getError: true,
    notError: true,
    requiresToken: true,
  });
};

//update personal Borrower Props type
interface updatePersonalBorrowerProps {
  type: string;
  name: string;
  identityCardNumber: string;
  phoneNumber: string;
  address: string;
  address2: string;
  email: string;
  level: string;
  imageIdentityCard: workImage[];
  imagePersonalProfile: workImage[];
  imageAccountBook: workImage[];
  borrowerID: number;
  zipCode: string;
}

export const updatePersonalBorrowerApi = (
  payload: updatePersonalBorrowerProps
) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/borrower/edit-borrower`,
    payload: payload,
    notError: true,
    requiresToken: true,
  });
};

interface detailPersonalBorrower {
  borrowerID: number;
  type: string;
}

//get detail
export const getDetailPersonalBorrowerApi = (
  params: detailPersonalBorrower
) => {
  return mainAxios.request({
    methodType: "GET",
    url: `/borrower/detail-borrower`,
    params: params,
    notError: true,
    requiresToken: true,
  });
};
